<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      size="lg"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="CloseModal()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-md-3">Group</label>
              <div class="col-md-3">
                <v-select
                  ref="groupSelectRef"
                  style="width: 100% !important"
                  class="custom-v-select"
                  placeholder="-- Select --"
                  :options="groupItem"
                  v-model="groupSelect"
                />
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CInput
              ref="rankRef"
              label="No"
              :horizontal="{ label: 'col-md-3', input: 'col-md-3' }"
              v-model="socreData.rankScore"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CTextarea
              ref="criteriaRef"
              label="Criteria"
              rows="3"
              horizontal
              placeholder=""
              v-model="socreData.scoreCriteria"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CInput
              ref="weightRef"
              label="Weight"
              :horizontal="{ label: 'col-md-3', input: 'col-md-3' }"
              v-model="socreData.scoreWeight"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <div role="group" class="form-group form-row">
              <label class="col-form-label col-sm-3">Status</label>
              <div class="col-md-9">
                <CSwitch
                  style="padding-top: 5px"
                  color="info"
                  shape="pill"
                  labelOn="on"
                  labelOff="off"
                  :checked.sync="socreData.isActive"
                />
              </div>
            </div>
            <!-- <div class="col-md-3">
              <label class="">Status</label>
            </div> -->
            <!-- <div class="col-md-9 pr-0">
              
            </div> -->
          </CCol>
        </CRow>
        <br />
        <CRow>
          <CCol md="12" style="padding: 10px">
            <label class="col-md-3">Score Detail</label>
            <CButton color="primary" class="mr-1" @click="OnClickAdd(null)"
              >Add</CButton
            >
          </CCol>
          <CCol md="12">
            <CDataTable :items="scoreItem" :fields="fields" hover sorter>
              <template #Action="{ item }">
                <!-- <td>
                  <CLink
                    class="text-primary"
                    @click="OnEditScorePointDetail(item.ScorePointID)"
                  >
                    Edit </CLink
                  >|
                  <CLink
                    class="text-primary"
                    @click="OnDeleteScorePointDetail(item.ScorePointID)"
                  >
                    Delete
                  </CLink>
                </td> -->
                <td class="py-2">
                  <CButton
                    color="success"
                    variant="outline"
                    square
                    size="sm"
                    style="margin-right: 5px"
                    @click="OnEditScorePointDetail(item.ScorePointID)"
                  >
                    Edit
                  </CButton>
                  <CButton
                    color="danger"
                    variant="outline"
                    square
                    size="sm"
                    @click="OnDeleteScorePointDetail(item.ScorePointID)"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton @click="SaveData()" color="primary"> Save </CButton>
        <CButton @click="CloseModal()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->

    <CModal
      :show.sync="showAddOrScorePointModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      size="lg"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="OnCloseScorePoint()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="12">
            <CInput
              label="Score Point"
              :horizontal="{ label: 'col-md-3', input: 'col-md-3' }"
              v-model="scorePointData.ScorePoint"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CTextarea
              label="Discription"
              rows="3"
              horizontal
              placeholder=""
              v-model="scorePointData.ScorePointDesc"
            />
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton @click="OnSaveScorePoint($event)" color="primary">
          Save
        </CButton>
        <CButton @click="OnCloseScorePoint()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";
import AddOrUpdateScoreModal from "@/components/modal/AddOrUpdateScoreModal.vue";

var URL_AddOrUpdate = `${store.getters.URL}/api/MasterScoreDetail/AddOrUpdateMasterCriteria`;
const titleAdd = "Add Criteria";
const titleEdit = "Edit Criteria";

const fields = [
  { key: "Action", label: "Action" },
  { key: "ScorePoint", label: "Score Point" },
  { key: "ScorePointDesc", label: "Discription" },
];

export default {
  name: "AddOrUpdateBuModal",
  components: {
    vSelect,
    AddOrUpdateScoreModal,
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("AddOrUpdateScoreModal-updated", v);
      },
    },
    BuIdFromProps: {
      get() {
        return this.buId;
      },
    },
  },
  props: {
    showModal: Boolean,
    item: {
      type: Object,
      default: null,
      required: false,
    },
    buId: Number,
    viewmode: {
      type: String,
      default: "view",
      required: false,
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      fields,
      titleModal: titleAdd,
      showAddOrScoreModal: false,
      showAddOrScorePointModal: false,
      scorePointData: {
        ScorePointID: 0,
        ScorePoint: null,
        ScorePointDesc: null,
      },
      socreData: {
        scoreID: 0,
        buId: 0,
        scoreGroup: null,
        rankScore: null,
        scoreCriteria: null,
        scoreWeight: null,
        isActive: true,
      },
      scoreId: 0,
      scoreItem: [],
      groupItem: [
        { value: "Project", label: "Project" },
        { value: "Market", label: "Market" },
      ],
      groupSelect: [],
    };
  },
  mounted() {},
  methods: {
    setDefault() {
      this.socreData.scoreID = 0;
      this.socreData.buId = null;
      this.socreData.scoreGroup = null;
      this.socreData.rankScore = null;
      this.socreData.scoreCriteria = null;
      this.socreData.scoreWeight = null;
      this.socreData.isActive = true;
      this.groupSelect = [];
      this.scoreItem = [];
    },
    setScorePointDefault() {
      //this.scoreItem = [];
      this.scorePointData.ScorePointID = 0;
      this.scorePointData.ScorePoint = null;
      this.scorePointData.ScorePointDesc = null;
    },
    CloseModal() {
      this.isshow = false;
      this.setDefault();
      this.setScorePointDefault();
    },
    OnClickAdd(item) {
      this.showAddOrScorePointModal = true;
    },
    GenDataScore(item) {
      // console.log("item", item);
      let model = null;
      model = item;

      if (model.ScoreGroup != null) {
        const result = this.groupItem.filter(
          (e) => e.value == model.ScoreGroup
        );
        //console.log("result", result);
        if (result.length > 0) {
          this.groupSelect = [];
          this.groupSelect = result;
        }
      }
      this.socreData.scoreID = model.ScoreID;
      this.socreData.buId = model.BuId;
      this.socreData.scoreGroup = model.ScoreGroup;
      this.socreData.rankScore = model.RankScore;
      this.socreData.scoreCriteria = model.ScoreCriteria;
      this.socreData.scoreWeight = model.ScoreWeight;
      this.socreData.isActive = model.IsActive;
      if (model.ScoreWeight != null) {
        this.scoreItem = model.ScoreDetail;
      }
      //console.log("socreData", this.socreData);
    },
    OnDeleteScorePointDetail(id) {
      const index = this.scoreItem.findIndex((e) => e.ScorePointID === id);
      this.scoreItem.splice(index, 1);
    },
    OnEditScorePointDetail(id) {
      // console.log("id", id);
      // console.log("this.scoreItem", this.scoreItem);
      let result = this.scoreItem.find((e) => e.ScorePointID == id);
      if (result != null) {
        this.scorePointData.ScorePointID = result.ScorePointID;
        this.scorePointData.ScorePoint = result.ScorePoint;
        this.scorePointData.ScorePointDesc = result.ScorePointDesc;
      }
      this.showAddOrScorePointModal = true;
    },
    OnSaveScorePoint() {
      const isAdd = this.scorePointData.ScorePointID == 0;
      let model = {
        BuId: this.BuIdFromProps,
        ScoreId: this.socreData.ScoreID,
        ScorePointID: 0,
        ScorePoint: null,
        ScorePointDesc: null,
        IsActive: true,
      };
      const length = this.scoreItem.length;
      let tempId = 0;
      //let rrrr = [];
      if (isAdd) {
        if (length == 0) {
          model.ScorePointID = 1;
        } else {
          model.ScorePointID = this.GetNextId(this.scoreItem);
        }
        model.ScorePoint = parseInt(this.scorePointData.ScorePoint);
        model.ScorePointDesc = this.scorePointData.ScorePointDesc;
        this.scoreItem.push(model);
      } else {
        const index = this.scoreItem.findIndex(
          (e) => e.ScorePointID === this.scorePointData.ScorePointID
        );
        this.scoreItem[index].scorePoint = parseInt(
          this.scorePointData.ScorePoint
        );
        this.scoreItem[index].ScorePointDesc =
          this.scorePointData.ScorePointDesc;
      }

      this.OnCloseScorePoint();
    },
    OnCloseScorePoint() {
      this.showAddOrScorePointModal = false;
      this.setScorePointDefault();
    },
    GetNextId(obj) {
      return (
        Math.max.apply(
          Math,
          obj.map(function (o) {
            return o.ScorePointID;
          })
        ) + 1
      );
    },
    ValidateField() {
      const colorRed = "red";
      let IsError = false;
      let model = this.socreData;
      let modelGroup = this.groupSelect;
      // console.log("vali", model);
      //console.log("valiG", modelGroup);
      if (modelGroup == null || modelGroup?.length == 0) {
        IsError = true;
        this.$refs.groupSelectRef.$el.children[0].style.borderColor = colorRed;
      } else {
        this.$refs.groupSelectRef.$el.children[0].style.borderColor = "";
      }

      if (model.rankScore == null || model.rankScore == "") {
        IsError = true;
        this.$refs.rankRef.$el.children[1].children[0].style.borderColor =
          colorRed;
      } else {
        this.$refs.rankRef.$el.children[1].children[0].style.borderColor = "";
      }

      if (model.scoreCriteria == null || model.scoreCriteria == "") {
        IsError = true;
        this.$refs.criteriaRef.$el.children[1].children[0].style.borderColor =
          colorRed;
      } else {
        this.$refs.criteriaRef.$el.children[1].children[0].style.borderColor =
          "";
      }

      if (model.scoreWeight == null || model.scoreWeight == "") {
        IsError = true;
        this.$refs.weightRef.$el.children[1].children[0].style.borderColor =
          colorRed;
      } else {
        this.$refs.weightRef.$el.children[1].children[0].style.borderColor = "";
      }

      return IsError == false;
    },
    SaveData(action) {
      if(this.ValidateField())
      {
        this.AddOrUpdateData();
      }
    },
    AddOrUpdateData() {
      axios
        .post(URL_AddOrUpdate, this.mapDTO(this.socreData))
        .then((res) => {
          if (res.status === 200) {
            this.$_toast_Noti("success", "success", "");
            this.$emit("saveCriteria-success");
            this.CloseModal();
          }
        })
        .catch((err) => {
          console.error("error AddOrUpdateData Criteria", err);
        });
    },
    mapDTO(item) {
      console.log("item", item);
      let param = {
        ScoreID: 0,
        RankScore: 0,
        ScoreCriteria: null,
        ScoreWeight: 0,
        ScoreGroup: null,
        BuId: 0,
        IsActive: true,
        ScoreDetail: [],
      };

      param.ScoreID = item.scoreID;
      param.RankScore = parseInt(item.rankScore);
      param.ScoreCriteria = item.scoreCriteria;
      param.ScoreWeight = parseInt(item.scoreWeight);
      if(this.isAdd){
        param.ScoreGroup = this.groupSelect.value;
        param.ScoreDetail = this.scoreItem;

      } else {
        param.ScoreGroup = this.groupSelect[0].value;
        param.ScoreDetail = this.scoreItem;
        param.ScoreDetail.forEach(e => {e.ScoreId = param.ScoreID});
        //console.log("scoreItem", this.scoreItem);
      }
      param.BuId = this.BuIdFromProps;
      param.IsActive = item.isActive;
      //console.log("groupSelect", this.groupSelect[0].value);
      // console.log("isAdd", this.isAdd);
      // console.log("groupSelect", this.groupSelect);
      //console.log("param", param);
      return param;
    },
  },
  watch: {
    showModal: function (newVal, oldVal) {
      // console.log("showModal", newVal);
     // console.log("item", this.item);
      if (newVal) {
        let temp = this.item;
        if (temp != null) {
          this.titleModal = titleEdit;
          this.GenDataScore(temp);
        } else {
          this.titleModal = titleAdd;
        }
      }
    },
  },
};
</script>
