<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      size="lg"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="OnClose()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="12">
            <CInput
              label="Score Point"
              :horizontal="{ label: 'col-md-3', input: 'col-md-3' }"
              v-model="scoreData.scorePoint"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="12">
            <CTextarea
              label="Discription"
              rows="3"
              horizontal
              placeholder=""
              v-model="scoreData.scoreDiscription"
            />
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton
          @click="OnSave()"
          v-if="viewmode !== 'view'"
          color="primary"
        >
          Save
        </CButton>
        <CButton @click="OnClose()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";

const titleAdd = "Add Score";
const titleEdit = "Edit Score";


export default {
  name: "AddOrUpdateScoreModal",
  components: {
    vSelect,
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      titleModal: titleAdd,
      scoreData: {
        id: null,
        scorePoint: null,
        scoreDiscription: null,
      },
      scoreDataTable:[],
    };
  },
  props: {
    showModal: Boolean,
    scoreId: Number,
    item: Array,
    viewmode: {
      type: String,
      default: "view",
      required: false,
    },
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(value) {
        this.$emit("AddOrUpdateScoreModal-updated", value);
      },
    },
    scorePointData: {
      get() {
        return this.scoreDataTable;
      },
      set(value) {
        this.$emit("saveAddOrUpdateScoreModal-success", value);
      },
    },
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.scoreData.id = null;
      this.scoreData.scorePoint = null;
      this.scoreData.scoreDiscription = null;
      this.scoreDataTable = [];
    },
    OnSave() {
      //console.log("scoreId", this.scoreId);
      //console.log("item", this.item);
      // console.log("store.getters.ScorePointData", store.getters.ScorePointData);
      // const lengthStore = store.getters.ScorePointData.length;
      // if(lengthStore == 0)
      // {
      //   console.log("len", lengthStore);
      //   store.commit('SetScorePointData', this.scoreData)
      //   console.log("store in con", store.getters.ScorePointData);
      // }
      let result = null;
      if(this.scoreId == 0)
      {
        if(this.scoreDataTable.length == 0) {
          this.scoreData.id = 1;
          //console.log("in mo", this.scoreData);
          //console.log("Com scorePointData", this.scorePointData);
          this.scoreDataTable.push(this.scoreData);
          this.scorePointData = this.scoreDataTable;
          //console.log("scoreDataTable", this.scoreDataTable);
        } else {
          this.scoreDataTable.push(this.scoreData);
        }
        result = this.scoreDataTable;
        //this.$emit("saveAddOrUpdateScoreModal-success", result);
      }
      else{

      }
      this.OnClose();
    },
    OnClose() {
      this.isshow = false;
      this.setDefault();
    },
  },
  watch: {
    item: function (newVal, oldVal) {
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal);
      if (newVal !== null) {
        //console.log("Item", newVal);
      } else {
        //console.log("Item null", newVal);

        // this.titleModal = titleAdd;
        // this.setDefault();
      }
      //console.log("this.insightProcessID",this.insightProcessID);
    },
  },
};
</script>
