<template>
  <CCard>
    <CCardHeader>
      <CCol>
        <h4 class="subhead-modal custom-header">Master Criteria</h4>
        <div class="col-md-12 text-right">
          <label style="margin-right: 10px">Business unit</label>
          <v-select
            class="custom-v-select"
            placeholder="-- Select --"
            :options="buItem"
            v-model="buSelect"
          />
          <CButton
            color="primary"
            class="mr-1 custom-header-button"
            @click="OnClickDetail(null, true)"
            >New</CButton
          >
        </div>
      </CCol>

      <!-- <div class="subhead-modal-action">
        </div> -->
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <h5>Project</h5>
        </CCol>
      </CRow>

      <CRow>
        <CCol md="12">
          <CDataTable
            :items="criteriaProjectData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
            tableFilter
          >
            <template #IsActive="{ item }">
              <td>
                <CBadge :color="GetBadge(item.IsActive)">
                  {{ item.IsActive == true ? "Active" : "InActive" }}
                </CBadge>
              </td>
            </template>
            <template #Action="{ item }">
              <!-- <td>
                <CLink class="text-primary" @click="OnClickDetail(item)"> Edit </CLink>|
                <CLink class="text-danger"> Delete </CLink>
              </td> -->
              <td class="py-2">
                <CButton
                  color="success"
                  variant="outline"
                  square
                  size="sm"
                  @click="OnClickDetail(item)"
                >
                  Edit
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
      <br />
      <CRow>
        <CCol md="12">
          <h5>Market</h5>
        </CCol>
      </CRow>
      <CRow>
        <CCol md="12">
          <CDataTable
            :items="criteriaMarketData"
            :fields="fields"
            items-per-page-select
            :items-per-page="10"
            :loading="IsLoading"
            hover
            sorter
            pagination
            tableFilter
          >
            <template #IsActive="{ item }">
              <td>
                <CBadge :color="GetBadge(item.IsActive)">
                  {{ item.IsActive == true ? "Active" : "InActive" }}
                </CBadge>
              </td>
            </template>
            <template #Action="{ item }">
              <!-- <td>
                <CLink class="text-primary" @click="OnClickDetail(item)"> Edit </CLink>|
                <CLink class="text-primary"> Delete </CLink>
              </td> -->
              <td class="py-2">
                <CButton
                  color="success"
                  variant="outline"
                  square
                  size="sm"
                  @click="OnClickDetail(item)"
                >
                  Edit
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>

      <AddOrUpdateCriteriaModal
        :showModal.sync="showAddOrUpdateModal"
        :item.sync ="criteriaObj"
        :buId.sync = "buSelectValue"
        :isAdd.sync = "isAdd"
        @AddOrUpdateScoreModal-updated="showAddOrUpdateModal = $event"
        @saveCriteria-success="GetDataAfterUpdate"
      />
    </CCardBody>
  </CCard>
</template>
<style>
.custom-header {
  padding-top: 5px;
  width: 250px;
  display: inline-block;
}
.custom-header-button {
  float: right;
}
.custom-v-select {
  width: 300px;
  display: inline-block;
  margin-right: 10px;
}
</style>

<script>
import axios from "axios";
import store from "../../store";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import AddOrUpdateCriteriaModal from "@/components/modal/AddOrUpdateCriteriaModal.vue";

const URL_GetBu = store.getters.URL + "/api/MasterBu/getall";
const URL_GetMasterCriteria =
  store.getters.URL + "/api/MasterScoreDetail/GetMasterCriteria";

const fields = [
  { key: "Action", label: "Action" },
  { key: "RankScore", label: "No." },
  { key: "ScoreCriteria", label: "Criteria Name" },
  { key: "ScoreWeight", label: "Weight" },
  { key: "IsActive", label: "Status" },
];

export default {
  name: "MasterCriteria",
  components: {
    AddOrUpdateCriteriaModal,
    vSelect,
  },
  data() {
    return {
      fields,
      IsLoading: false,
      showAddOrUpdateModal: false,
      criteriaObj: null,
      IdeationManagerViewmode: "view",
      buItem: [{ value: "", label: "-- Select --" }],
      buSelect: [],
      buSelectValue: 0,
      isAdd: false,
      criteriaProjectData: [],
      criteriaMarketData: [],
    };
  },
  mounted() {
    this.GetBuItem();
  },
  methods: {
    GetBuItem() {
      axios.get(URL_GetBu).then((res) => {
        let options = [{ value: "", label: "-- Select --" }];
        //console.log("res", res);
        if (res !== null) {
          res.data.forEach(function (data) {
            options.push({
              value: data.BUID,
              label: data.BUDesc,
            });
          });
        }
        this.buItem = options;
      });
    },
    ClearDataTable() {
      this.criteriaProjectData = [];
      this.criteriaMarketData = [];
    },
    GetGetMasterCriteriaGroupProject(buId) {
      axios
        .get(`${URL_GetMasterCriteria}?buId=${buId}&group=Project`)
        .then((res) => {
          this.criteriaProjectData = res.data;
        });
    },
    GetGetMasterCriteriaGroupMarket(buId) {
      axios
        .get(`${URL_GetMasterCriteria}?buId=${buId}&group=Market`)
        .then((res) => {
          this.criteriaMarketData = res.data;
        });
    },
    GetDataAfterUpdate()
    {
      const buId = this.buSelect.value;
      this.ClearDataTable();
      this.GetGetMasterCriteriaGroupProject(buId);
      this.GetGetMasterCriteriaGroupMarket(buId);
    },
    GetBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "danger";
        default:
          "primary";
      }
    },
    OnClickDetail(item, isAdd = false) {
      this.isAdd = isAdd;
      this.showAddOrUpdateModal = true;
      this.criteriaObj = item;
      this.buSelectValue = this.buSelect.value;
    },
  },
  watch: {
    buItem: function (newValue, oldValue) {
      //console.log("item change", newValue);
      if (newValue.length > 0) {
        this.buSelect = newValue[1];
      }
    },
    buSelect: function (newValue, oldValue) {
      //console.log("itembuSelect change", newValue);
      if (newValue != null) {
        const buId = newValue.value;
        this.ClearDataTable();
        this.GetGetMasterCriteriaGroupProject(buId);
        this.GetGetMasterCriteriaGroupMarket(buId);
      }
    },
  },
};
</script>
